
import { Vue, Component } from "vue-property-decorator";
import { AdminService } from "@/api/AdminServiceApi";
import { GetSupportedLocales } from "@/utility/LanguageHelper";
import { QuotationService } from "@/api/QuotationServiceApi";
import QuotationCategory from "@/models/QuotationCategory";
import { IBlogEntry } from "@/models/BlogEntry";

@Component({
  components: {}
})
export default class DashboardBlogEntries extends Vue {
  blogTitle: string = "";
  blogText: string = "";
  author: string = "";
  blogUrl: string = "";
  metaTitle: string = "";
  metaDescription: string = "";
  selectedLang: string = "";
  files: Array<File> = [];

  selectedCategoryOption: QuotationCategory | null = null;
  categoryOptions = Array<QuotationCategory>();

  async created() {
    this.selectedLang = "sv";

    await QuotationService.GetCategories()
      .then(response => {
        this.categoryOptions = response;
      })
      .catch(() => {});
  }

  async createBlogEntry() {
    let blogEntry: IBlogEntry = {
      title: this.blogTitle,
      text: this.blogText,
      author: this.author,
      lang: this.selectedLang,
      quotationCategoryId: this.selectedCategoryOption!.id,
      blogUrl: this.blogUrlPath,
      metaTitle: this.metaTitle,
      metaDescription: this.metaDescription,
      file: { id: 0, fileName: "", type: "", base64: "" }
    };

    if (this.files.length > 0) {
      let file: File = this.files[0];
      const reader = new FileReader();
      reader.onload = async event => {
        if (event.target == null) {
          return;
        }

        blogEntry.file.fileName = file.name;
        blogEntry.file.type = file.type;
        blogEntry.file.base64 = event.target.result;
        await this.postBlogEntry(blogEntry);
      };

      reader.readAsDataURL(file);
    } else {
      await this.postBlogEntry(blogEntry);
    }
  }

  async postBlogEntry(blogEntry: IBlogEntry) {
    await AdminService.CreateBlogEntry(blogEntry)
      .then(() => {
        this.$swal.fire({
          titleText: "Blogg inlägg sparat",
          confirmButtonText: "Ok",
          icon: "success"
        });

        this.blogTitle = "";
        this.blogText = "";
        this.blogUrl = "";
        this.author = "";
        this.metaTitle = "";
        this.metaDescription = "";
        this.files = [];
        this.selectedCategoryOption = null;
      })
      .catch(() => {
        this.$swal.fire({
          titleText: "Någonting gick fel",
          confirmButtonText: "Ok",
          icon: "error"
        });
      });
  }

  get imagePreview() {
    if (this.files.length > 0) {
      return URL.createObjectURL(this.files[0]);
    }
  }

  get supportedLocales() {
    return GetSupportedLocales();
  }

  get blogUrlPath() {
    return this.blogUrl;
  }

  addFileSelect(event: any) {
    if (event.target == null) {
      return;
    }

    const selectedFiles = event.target.files;
    if (!selectedFiles) {
      return;
    }

    this.files = [];
    [...selectedFiles].forEach(f => {
      this.files.push(f);
    });
  }

  addFileDrop(event: DragEvent): void {
    if (event.dataTransfer == null) {
      return;
    }

    const droppedFiles = event.dataTransfer.files;
    if (!droppedFiles) {
      return;
    }

    this.files = [];
    [...droppedFiles].forEach(f => {
      this.files.push(f);
    });
  }
}
